p {
    color: #007bff;
}
h2 {
    color: #007bff;
}
.text-orange {
    color: #d77c15;
}
.card {
    height: 100%;
}
.content {
    margin-top:5%;
    padding-bottom: 10%;
}