.amplify-tabs {
    display: none;
}
.jumbotron {
  padding: 1rem 1rem;
  background-color: #2f353c;
  border-radius: .3rem;
}
.align-middle {
  margin: 15% 2% !important;
  display: table;
}
.profile-pic {
  max-height: 80px;
}