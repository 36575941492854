.bg-dark2 {
    background-color: #252525 !important;
}
.bg-pattern {
    background-color: #000000;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23252525' fill-opacity='1' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}
.bg-black {
    background-color: #000000 !important;
}
.login {
    margin-right: 2%;
}